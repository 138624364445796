<template>
  <div class="mainform">
    <div class="mainHeader">查看返修单</div>
      <div class="form">
        <el-form class="content bascform" ref="form" :model="form" :class="{disabled:formDisabled}" :disabled="formDisabled" label-width="120px">
          <div class="col col4">
            <el-form-item label="返修单号">
                <el-input  v-model="form.repairOrders"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="公司名称">
                <el-input  v-model="form.companyName" placeholder="编码/名称" ></el-input>
            </el-form-item>
          </div>
          <div class="col col4" style="vertical-align: top;">
            <el-form-item label="返修类型">
              <span class="onlyText">
                {{form.repairType==1?'RMA返修':'DOA返修'}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="状态">
              <span class="onlyText">
                {{form.status==1?'待我确认':form.status==2?'待客户修改':form.status==3?'待客户发货':form.status==4?'待我收货'
                :form.status==5?'待我发货':form.status==6?'待客户收货':form.status==7?'待客户确认':'已完成'}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4" style="vertical-align: top;">
            <el-form-item label="返修地址">
              <div class="onlyText">{{form.repairAddress}}</div>
            </el-form-item>
          </div>
          <div class="col col4">
              <el-form-item label="收货地址(基地)">
                <div class="onlyText">{{form.receivingAddress}}</div>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="备注">
                <el-input  v-model="form.bz"></el-input>
            </el-form-item>
          </div>
          <div class="forrepair">
            <el-form-item label="待修部件"></el-form-item>
          </div>
        </el-form>
        <div class="content bascform">
          <div class="tableList" >
            <div class="table">
              <el-table  style="width: 100%"  :data="formList">
                <el-table-column prop="sequenceNum" label="序号" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="materialCode" label="物料编号" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="materialDesc"  label="物料描述" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="supplierMaterialCode" label="我的物料编号" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="supplierMaterialDesc"  label="我的物料描述" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="plant" label="工厂" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="storageLand" label="库位" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="materialNum"  label="数量/单位"></el-table-column>
                <!-- <el-table-column prop=""  label="查看SN">
                  <template #default="scope">
                    <el-button size="mini" type="text" @click="handEdit(scope.$index, scope.row)">SN</el-button>
                  </template>
                </el-table-column> -->
                <el-table-column prop="outboundBase"  label="出库基地" :show-overflow-tooltip="true"></el-table-column>
              </el-table>
            </div>
        </div>
        </div>
    </div>
    <!-- 1待我确认 -->
    <div class="action" v-if="form.status==1">
      <el-button  type="primary" size="medium" @click="refuseBtn">拒绝</el-button>
      <el-button  type="primary" size="medium" @click="confirmBtn">确认</el-button>
    </div>
    <!-- 待我收货 -->
    <div class="action" v-if="form.status==4">
      <el-button  type="primary" size="medium" @click="confirmReceipt ">收货</el-button>
    </div>
    <!-- 待我发货 -->
    <div class="action" v-if="form.status==5">
      <el-button  type="primary" size="medium" @click="shipmentsBtn">发货/折款</el-button>
    </div>
  <dialog-box title="查看SN"  componentName="SNDialog" dialogWidth="826px" :dialogShow="sNdialogShow" @handleClose='handleClose'
  :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', size: 'mini', action: 'handleAffirm', type: 'primary' }]" ></dialog-box>

  <dialog-box title="拒绝" componentName="RefuseBtnDialog"  ref="refusereceipt" dialogWidth="826px" :dialogShow="dialogShow" @handleClose='handleClose'
  @confirmRefused="confirmRefused" :buttonData="[{ label: '确认', size: 'mini', action: 'confirmRefused', type: 'primary' }]" ></dialog-box>

  <dialog-box title="确认" componentName="ConfirmBtnDialog" ref="conform" :dialogShow="confirmShow" @handleClose='handleClose'   @affirmapply="affirmapply"
  :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', size: 'mini', action: 'affirmapply', type: 'primary' }]" ></dialog-box>

  <dialog-box title="收货" componentName="ConfirmReceiptDialog" :dialogShow="confirmReceiptShow" :receivinggoods="form" @handleClose='handleClose'   @confirmreceipt="confirmreceipt"
  :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', size: 'mini', action: 'confirmreceipt', type: 'primary' }]" ></dialog-box>
  <dialog-box title="发货/折款" componentName="ShipmentsBtnDialog" ref="shipdialog" :receivingData="form" dialogWidth="826px" :dialogShow="shipmentsBtnShow" @handleClose='handleClose' @ShipmentsAffirm="ShipmentsAffirm"
  :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', size: 'mini', action: 'ShipmentsAffirm', type: 'primary' }]" ></dialog-box>
</div>
</template>

<script>
import { requestForm, request } from '@/assets/js/http.js'
import DialogBox from '@/components/plug/DialogBox.vue'
export default {
  name: 'SupplierRepairDetail',
  components: { DialogBox },
  data: function () {
    return {
      formdata: {
        startdate: '',
        endstate: ''
      },
      repairTypeOption: [
        { label: 'RMA返修', value: '1' },
        { label: 'DOA返修', value: '2' }
      ],
      formDisabled: true,
      dialogShow: false, // 拒绝
      confirmShow: false,
      confirmReceiptShow: false,
      shipmentsBtnShow: false,
      sNdialogShow: false, // 查看SN
      form: {},
      id: '',
      formList: [],
      returnNum: ''
    }
  },
  created () {
    var id = this.$route.query.id
    requestForm('/api/repair/repair/querySupplierDetails?id=' + id, 'post').then((res) => {
      if (res.code === '200') {
        this.form = res.data.repairDto
        this.formList = res.data.repairDtos
      }
    })
  },
  // mounted () {
  // },
  methods: {
    handleClose () {
      this.dialogShow = false
      this.shipmentsBtnShow = false
      this.confirmShow = false
      this.confirmReceiptShow = false
      this.sNdialogShow = false
    },
    confirmRefused () { // 拒绝按钮弹框的确认按钮  //状态修改为待客户修改
      var isPass = true
      var data = this.$refs.refusereceipt.$refs.content.$refs.form
      console.log('data', data)
      data.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      this.dialogShow = false
      const obj = {
        id: this.$route.query.id,
        RefusedText: this.$refs.refusereceipt.$refs.content.form.RefusedText // 拒绝原因
      }
      console.log('000', obj.RefusedText)
      requestForm('/api/repair/repair/updateSupplierRefused', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            message: '拒绝成功',
            type: 'success'
          })
          this.$router.push({
            path: '/InventoryDelivery/SupplierRepair'
          })
        }
      })
    },
    affirmapply () { // 确认按钮弹框的确认按钮   列表状态修改为待客户发货
      var isPass = true
      var data = this.$refs.conform.$refs.content.$refs.form
      console.log('data', data)
      data.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      const obj = {
        repairOrders: this.form.repairOrders, // 返修单号
        repairAddress: this.$refs.conform.$refs.content.form.repairAddress,
        repairUserName: this.$refs.conform.$refs.content.form.repairUserName,
        repairUserPhone: this.$refs.conform.$refs.content.form.repairUserPhone,
        rma: this.$refs.conform.$refs.content.form.rma,
        storage: this.$refs.conform.$refs.content.form.storage.lable,
        storageCode: this.$refs.conform.$refs.content.form.storage.storageCode,
        storageType: this.$refs.conform.$refs.content.form.storage.storageType,
        supplierBz: this.$refs.conform.$refs.content.form.supplierBz,
        id: this.$route.query.id
      }
      console.log('9800988', obj.repairOrders)
      request('/api/repair/repair/updateSupplierAffirm', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            message: '确认成功',
            type: 'success'
          })
          this.$router.push({
            path: '/InventoryDelivery/SupplierRepair'
          })
        }
      })
    },
    confirmreceipt () { // 收货按钮的确认按钮
      var repairDto = this.form
      console.log('repairDto', repairDto)
      var repairDetailsDtos = this.formList
      console.log('repairDetailsDtos', repairDetailsDtos)
      const obj = {
        repairDto: this.form,
        repairDetailsDtos: this.formList,
        id: this.$route.query.id
      }
      request('/api/repair/repair/updateTakeState', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            message: '确认收货成功',
            type: 'success'
          })
          this.$router.push({
            path: '/InventoryDelivery/SupplierRepair'
          })
        }
      })
    },
    ShipmentsAffirm () { // 发货折款的确认按钮
      // 实物返回
      // 返修折款
      // 实物返回+折款
      var radiodata = this.$refs.shipdialog.$refs.content.form.radio
      var url = ''
      var obj = {}
      if (radiodata === '1') {
        url = '/api/repair/repair/updateSupplieShipments'// 实物返回-发货
        obj = {
          repairDto: this.form,
          repairDetailsDtos: this.formList,
          id: this.$route.query.id,
          list: this.$refs.shipdialog.$refs.content.returnData
        }
      } else if (radiodata === '2') {
        url = '/api/repair/repair/updateSheKuan' // 折款
        obj = {
          repairDto: this.form,
          repairDetailsDtos: this.formList,
          id: this.$route.query.id,
          list: this.$refs.shipdialog.$refs.content.RepairSheKuan
        }
      } else {
        url = '/api/repair/repair/updateDeliverySheKuan' // 实物返回+折款
        obj = {
          repairDto: this.form,
          repairDetailsDtos: this.formList,
          id: this.$route.query.id,
          list: this.$refs.shipdialog.$refs.content.returnRepairSheKuan,
          zhekuanbj: this.$refs.shipdialog.$refs.content.zhekuanbj
        }
      }
      request(url, 'POST', obj).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '确认发货成功',
            type: 'success'
          })
          this.$router.push('/InventoryDelivery/SupplierRepair')
        }
      })
    },
    refuseBtn () { // 拒绝按钮
      this.dialogShow = true
    },
    confirmBtn () { // 确认按钮
      this.confirmShow = true
    },
    confirmReceipt () {
      this.confirmReceiptShow = true
    },
    shipmentsBtn () { // 发货折款按钮
      this.shipmentsBtnShow = true
    },
    handEdit () {
      this.sNdialogShow = true
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  @import '@/assets/css/tableList.scss';
.mainform .form .bascform {
    padding: 0px 0;
}
.tableList{
  padding: 0px 0px 0px 150px;
}
.forrepair{
    padding: 10px;
}
.action{
  margin-top: 35px;
}
</style>
